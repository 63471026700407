import React from 'react';
import { MateLogoFull } from '@/components/common/MateLogo/MateLogoFull';
import { MateLogoSquare } from '@/components/common/MateLogo/MateLogoSquare';

export const HeaderLogoIcon = () => (
  <>
    <MateLogoFull className="show-for-xlarge" />
    <MateLogoSquare className="hide-for-xlarge" />
  </>
);
