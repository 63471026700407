import React from 'react';
import { cn } from '@/lib/classNames';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import styles from './IconLogoFull.module.scss';

export const IconLogoFullDeprecated: FCIcon = ({ className, ...rest }) => (
  <BaseIcon
    {...rest}
    className={cn(className, styles.logoIcon)}
    width="147"
    height="48"
    viewBox="0 0 147 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M37.1905 0C42.7922 0 47.3333 4.60507 47.3333 10.2857V37.7143C47.3333 43.3949 42.7922 48 37.1905 48H10.1429C4.54111 48 0 43.3949 0 37.7143V10.2857C0 4.60507 4.54111 0 10.1429 0H37.1905ZM21.5058 17.1429H24.7474V31.4286H21.5058V23.1224L17.8388 29.1837H17.4741L13.8071 23.1224V31.4286H10.5655V17.1429H13.8071L17.6564 23.4898L21.5058 17.1429ZM33.7289 22.1837V21.2245H36.7679V31.4286H33.7289V30.4694C33.0603 31.2449 32.0676 31.7143 30.7102 31.7143C28.0561 31.7143 25.868 29.3673 25.868 26.3265C25.868 23.2857 28.0561 20.9388 30.7102 20.9388C32.0676 20.9388 33.0603 21.4082 33.7289 22.1837ZM28.907 26.3265C28.907 27.8571 29.92 28.8163 31.3179 28.8163C32.7159 28.8163 33.7289 27.8571 33.7289 26.3265C33.7289 24.7959 32.7159 23.8367 31.3179 23.8367C29.92 23.8367 28.907 24.7959 28.907 26.3265Z" fill="currentColor" />
    <path d="M72.6312 7H69.9269L66.1181 13.3747L62.2902 7H59.586V20.4812H62.2141V11.7569L65.9657 17.9968H66.2704L70.0221 11.7377V20.4812H72.6312V7Z" fill="currentColor" />
    <path d="M81.8567 10.8518V11.988C81.1711 11.1214 80.1427 10.5821 78.7525 10.5821C76.2196 10.5821 74.1248 12.7969 74.1248 15.6665C74.1248 18.536 76.2196 20.7508 78.7525 20.7508C80.1427 20.7508 81.1711 20.2115 81.8567 19.3449V20.4812H84.3134V10.8518H81.8567ZM79.2095 18.382C77.7051 18.382 76.5815 17.2842 76.5815 15.6665C76.5815 14.0487 77.7051 12.951 79.2095 12.951C80.7331 12.951 81.8567 14.0487 81.8567 15.6665C81.8567 17.2842 80.7331 18.382 79.2095 18.382Z" fill="currentColor" />
    <path d="M91.6068 13.2399V10.8518H89.4548V8.15553L86.9981 8.90662V10.8518H85.3413V13.2399H86.9981V17.2457C86.9981 19.8456 88.1598 20.8663 91.6068 20.4812V18.2279C90.1975 18.3049 89.4548 18.2857 89.4548 17.2457V13.2399H91.6068Z" fill="currentColor" />
    <path d="M94.7363 16.6872H101.916C101.973 16.3598 102.011 16.0324 102.011 15.6665C102.011 12.8354 100.011 10.5821 97.193 10.5821C94.203 10.5821 92.1653 12.7969 92.1653 15.6665C92.1653 18.536 94.184 20.7508 97.3834 20.7508C99.2116 20.7508 100.64 19.9997 101.535 18.6901L99.5544 17.5346C99.1355 18.0931 98.3737 18.4975 97.4215 18.4975C96.1265 18.4975 95.0791 17.9583 94.7363 16.6872ZM94.6982 14.7613C94.9838 13.5287 95.8789 12.8162 97.193 12.8162C98.2213 12.8162 99.2497 13.3747 99.5544 14.7613H94.6982Z" fill="currentColor" />
    <path d="M66.7085 29.5087V30.645C66.0229 29.7784 64.9945 29.2391 63.6043 29.2391C61.0714 29.2391 58.9766 31.4539 58.9766 34.3234C58.9766 37.193 61.0714 39.4078 63.6043 39.4078C64.9945 39.4078 66.0229 38.8685 66.7085 38.0019V39.1381H69.1651V29.5087H66.7085ZM64.0613 37.0389C62.5568 37.0389 61.4332 35.9412 61.4332 34.3234C61.4332 32.7057 62.5568 31.608 64.0613 31.608C65.5849 31.608 66.7085 32.7057 66.7085 34.3234C66.7085 35.9412 65.5849 37.0389 64.0613 37.0389Z" fill="currentColor" />
    <path d="M75.5445 39.4078C77.4108 39.4078 79.0295 38.4063 79.8484 36.9041L77.7155 35.6716C77.3346 36.4612 76.5157 36.9426 75.5254 36.9426C74.059 36.9426 72.9735 35.8449 72.9735 34.3234C72.9735 32.7827 74.059 31.685 75.5254 31.685C76.4967 31.685 77.3156 32.1857 77.6964 32.9753L79.8103 31.7235C79.0295 30.2406 77.4108 29.2391 75.5445 29.2391C72.6498 29.2391 70.5168 31.4539 70.5168 34.3234C70.5168 37.193 72.6498 39.4078 75.5445 39.4078Z" fill="currentColor" />
    <path d="M87.8548 29.5087V30.645C87.1692 29.7784 86.1408 29.2391 84.7506 29.2391C82.2178 29.2391 80.1229 31.4539 80.1229 34.3234C80.1229 37.193 82.2178 39.4078 84.7506 39.4078C86.1408 39.4078 87.1692 38.8685 87.8548 38.0019V39.1381H90.3115V29.5087H87.8548ZM85.2077 37.0389C83.7032 37.0389 82.5796 35.9412 82.5796 34.3234C82.5796 32.7057 83.7032 31.608 85.2077 31.608C86.7312 31.608 87.8548 32.7057 87.8548 34.3234C87.8548 35.9412 86.7312 37.0389 85.2077 37.0389Z" fill="currentColor" />
    <path d="M99.376 25.657V30.645C98.6904 29.7591 97.6811 29.2391 96.2909 29.2391C93.739 29.2391 91.6441 31.4539 91.6441 34.3234C91.6441 37.193 93.739 39.4078 96.2909 39.4078C97.6811 39.4078 98.6904 38.8878 99.376 38.0019V39.1381H101.833V25.657H99.376ZM96.7479 37.0389C95.2244 37.0389 94.1008 35.9412 94.1008 34.3234C94.1008 32.7057 95.2244 31.608 96.7479 31.608C98.2524 31.608 99.376 32.7057 99.376 34.3234C99.376 35.9412 98.2524 37.0389 96.7479 37.0389Z" fill="currentColor" />
    <path d="M105.774 35.3442H112.954C113.011 35.0168 113.049 34.6894 113.049 34.3234C113.049 31.4924 111.05 29.2391 108.231 29.2391C105.241 29.2391 103.203 31.4539 103.203 34.3234C103.203 37.193 105.222 39.4078 108.422 39.4078C110.25 39.4078 111.678 38.6567 112.573 37.3471L110.593 36.1916C110.174 36.7501 109.412 37.1545 108.46 37.1545C107.165 37.1545 106.117 36.6152 105.774 35.3442ZM105.736 33.4183C106.022 32.1857 106.917 31.4731 108.231 31.4731C109.259 31.4731 110.288 32.0316 110.593 33.4183H105.736Z" fill="currentColor" />
    <path d="M124.915 29.2391C123.62 29.2391 122.668 29.7591 122.077 30.6258C121.544 29.7398 120.668 29.2391 119.487 29.2391C118.268 29.2391 117.373 29.7206 116.84 30.5295V29.5087H114.383V39.1381H116.84V33.7264C116.84 32.282 117.583 31.5502 118.63 31.5502C119.659 31.5502 120.23 32.2435 120.23 33.3798V39.1381H122.687V33.7264C122.687 32.282 123.372 31.5502 124.458 31.5502C125.486 31.5502 126.057 32.2435 126.057 33.3798V39.1381H128.514V33.2064C128.514 30.7991 127.086 29.2391 124.915 29.2391Z" fill="currentColor" />
    <path d="M136.223 29.5087L134.11 35.9797L131.634 29.5087H128.949L132.853 38.83C132.396 40.1203 131.71 40.6981 130.358 40.6789V42.9899C132.777 43.1247 134.395 41.9114 135.386 39.1381L138.852 29.5087H136.223Z" fill="currentColor" />
  </BaseIcon>
);
