import React, { FC, memo } from 'react';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { Features } from '@/controllers/features/features.constants';
import { IconLogoFullDeprecated } from '@/components/ui/icons/IconLogoFullDeprecated';
import { IconLogoFull } from '@/components/ui/icons/IconLogoFull';

type Props = {
  className?: string;
};

export const MateLogoFull: FC<Props> = memo(({
  className,
}) => {
  const features = useFeatures();
  const isLogoRedesignEnabled = features.isEnabled(
    Features.MateLogoRedesign,
  );

  return isLogoRedesignEnabled
    ? (
      <IconLogoFull className={className} />
    )
    : (
      <IconLogoFullDeprecated className={className} />
    );
});
